const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const NavItem = styled(Box)({
    component: "p",
    display: "flex",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "var(--font-family)",
    cursor: "pointer",
    alignItems: "center",
});

export default NavItem;