import React from 'react';

const BeamsPanels = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    return (
        <div>
            Beams and panels
        </div>
    );
};

export default BeamsPanels;