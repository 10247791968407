import React, { useState } from 'react';
import './formComponent.css'

const FormComponent = (props) => {
    const [lostFocused, setLostFocused] = useState(false);
    const { label, errorMessage, onChange, id, type, options, ...inputProps } = props;

    const handleFocus = (e) => {
        setLostFocused(true);
    };

    return (
        <div className="formComponent">
            {
                type === 'text' || type === 'email' || type === 'date' || type === 'password'?

                <div className="formComponent">
                    <label>{label}</label>
                    <input {...inputProps} onChange={onChange} onBlur={handleFocus}
                           lostFocus = {lostFocused.toString()}/>
                    <span>{errorMessage}</span>
                </div> :

                type === 'select'?

                <div className="formComponent">
                    <label>{label}</label>
                    <select{...inputProps} onChange={onChange}>{options.map((option) => (
                        <option value={option.value}>{option.content}</option>
                    ))}</select>
                </div> :

                type === 'textarea'?

                <div className="formComponent">
                    <label>{label}</label>
                    <textarea{...inputProps} onChange={onChange}/>
                </div> :

                    null
            }
        </div>
    );
};

export default FormComponent;