import React, {useEffect} from 'react';
import { FormworkSolutions } from "containers";
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {self_sinking1, self_sinking2} from "containers/formwork-solutions/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";

const MASelfSinking = ({isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile,
                           isFoldDevice}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            GD600 is a trench shoring system to protect excavation and
                            canalization works up to 6,00m depth and 5,00m width.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The versatile and easily adjustable formwork for curved walls allowing
                            the casting of curvilinear, closed or semicircular walls with a variable radius
                            starting from 2.50m.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The versatile and easily adjustable formwork for curved walls allowing
                            the casting of curvilinear, closed or semicircular walls with a variable radius
                            starting from 2.50m.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>
                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The versatile and easily adjustable formwork for curved walls allowing
                            the casting of curvilinear, closed or semicircular walls with a variable radius
                            starting from 2.50m.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The versatile and easily adjustable formwork for curved walls allowing
                            the casting of curvilinear, closed or semicircular walls with a variable radius
                            starting from 2.50m.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            MA Self-Sinking System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The versatile and easily adjustable formwork for curved walls allowing
                            the casting of curvilinear, closed or semicircular walls with a variable radius
                            starting from 2.50m.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The system is used already assembled in the job site.
                                </Features>

                                <Features>The MA trench shoring is made up of a standard 3.00m x H=2.00m panels, connected with adjustable spacers.If excavation is deeper, it’s possible to install elevation elements.By combining elevation panels to the standard ones, the maximum depth reachable is 4,40m in absolute safety.
                                </Features>

                                <Features>The system is designed to withstand a pressure up to 22 kN/m².
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={self_sinking2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default MASelfSinking;