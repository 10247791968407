import React, {useEffect} from 'react';
import { FormworkSolutions } from "containers";
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {aluplus1, aluplus2, aluplus3} from "containers/formwork-solutions/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";

const AluplusSlab = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>
                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Aluplus Slab Formwork System

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Aluplus is the modular, versatile and lightweight formwork system with an aluminum
                            frame that allows you to increase construction productivity by reducing timing for the casting on
                            site of slabs for the construction of multi-storey structures in the industrial, commercial and
                            residential sectors.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>The low weight of the high-strength aluminum panels (14,67 kg / sqm with
                                    112,5x150cm panel size) ensures easy and practical handling</Features>

                                <Features>Rational and systematic assembly reduces errors and arming times; moreover,
                                    the few and cyclical phases that characterize the system allow the mounting
                                    with few operators.</Features>

                                <Features>Possibility to use CEP steel props or ALUCP in aluminum depending on the thickness of the slab.</Features>

                                <Features>The particular shaping of the upper portion of the Aluplus drop-head,
                                    together with the early striking system, allows the assembly and disassembly
                                    of the formworks by operating bottom-up, avoiding overturning and accidental
                                    falls in full compliance with safety regulations.</Features>

                                <Features>Practical hooking system that allows a quick and safe installation and
                                    removal of the Aluplus drop head from the prop</Features>

                                <Features>Thanks to the Aluplus drop head, it is possible to proceed to early striking,
                                    therefore to recover the panel formworks for a faster proceeding of the
                                    works while keeping the slab shored, benefitting in time and labor costs</Features>

                                <Features>The phenolic plywood ensures a high quality casting and a high number of
                                    reuses, while keeping costs low</Features>

                                <Features>The PVC covers guarantee a perfect sealing of the deck preserving heads and
                                    props from concrete residues</Features>

                                <Features>The perimeter or edge compensations and those around pillars are easy to
                                    realise through four compensation beams</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Modular system with horizontal development consisting of a light formwork
                                    composed by a 9mm birch plywood panel with phenolic coating framed in an
                                    aluminum structure with a 12cm high perimeter elements and transverse
                                    elements, dedicated steel drop-head and props.The maximum weight of
                                    24.5kg of the larger formwork (112.5x150cm, equal to about 1.69sqm) together
                                    with the lightness of the other elements of the system, gives the Aluplus
                                    system the possibility of being handled in compliance with safety standards,
                                    limiting the use of the crane.
                                </Features>

                                <Features>The formwork is placed on the drop heads (Art. 21365) using the special
                                    anti-fall and anti-overturning profile. Always operating bottom-up, the
                                    rotation / lifting is then carried out to a horizontal position of the
                                    formwork with the aid of the assembly rod, followed by the installation
                                    of the necessary props equipped with drop-heads. The systematic sequence
                                    of assembly is repeated cyclically without variations allowing the rapid
                                    arming of the system.
                                </Features>

                                <Features>The striking phase can be anticipated compared to the canonical 28 days
                                    thanks to the 21365 Aluplus drop-head that allows the recovery of the panels,
                                    leaving the props in place to shore the casted slab for all the time
                                    necessary. The panels recovered in advance can immediately be reused
                                    (for example on the upper floor) with a new series of props, reducing
                                    the number of components necessary for the progress of the work and the
                                    time required to complete it.
                                </Features>

                                <Features>The Aluplus system is used in civil, residential and multi-storey buildings
                                    when a simple, fast and safe product is required, allowing the early recovery
                                    of the formwork for rapid realisation of multiple casted slabs.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                COMPENSATIONS
                            </Typography>

                            <Typography
                                marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                The system allows the possibility of compensations around or along the
                                discontinuities of the surface of the slab to be casted through the dedicated
                                compensation beams. The most realized compensations are:
                            </Typography>

                            <Box component="ul">
                                <Features>Compensations around a column.</Features>

                                <Features>Perimeter compensations (longitudinal and transversal).</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={aluplus1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default AluplusSlab;