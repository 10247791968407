import React, {useEffect, useState} from 'react';
import {FlexBox} from "../../components";
import {Button, TextField, Typography} from "@mui/material";
import ReactQuill from "react-quill";
import axios from "axios";
import {SERVER_BASE_API_URL, SERVER_BASE_URL, CLIENT_BASE_URL} from "env"

const EditBlog = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const params = window.location.search
    const id = new URLSearchParams(params).get('id')

    const [value, setValue] = useState('');
    const [imgFile, setImgFile] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [success, setSuccess] = useState('');

    const blogContent = {
        "title": title,
        "body": value,
        "image": image,
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        const fetchBlogPost = async () => {
            try {
                const { data: { blog } } = await axios.get(`${SERVER_BASE_API_URL}/blogs/${id}`)

                setTitle(blog.title)
                setValue(blog.body)
                setImage(blog.image)

            } catch (err) {
                if (err.response){
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`Error: ${err.message}`)
                }
            }
        }

        fetchBlogPost()
    }, [])

    const modules = {
        toolbar: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{font: []}],
            [{size: []}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"},],
            //["link", "image", "video"],
            ["link"],

            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],

            ['clean']
        ]
    }

    const imageUpload = async (e) => {
        setImage('w/null')
        setSuccess('')
        setImgFile(e.target.files[0])
    }

    const submit = async () => {
        try {
            if (!image.startsWith('blogImages')){
                const formData = new FormData()
                formData.append('image', imgFile)

                const {data:{image:{src}}} = await axios.post(`${SERVER_BASE_API_URL}/blogs/uploads`, formData,{
                    headers:{
                        'Content-Type':'multipart/form-data'
                    }
                })

                console.log(`src ${src}`)
                setImage(src)
                console.log(image)
                blogContent.image = src
            }

            await axios.patch(`${SERVER_BASE_API_URL}/blogs/${id}`, blogContent)

            setTitle('')
            setImgFile('')
            setValue('')
            setSuccess('Blog post edited successfully')

        } catch (err) {
            setImage(null)
            setSuccess(`Error: ${err.message}`)

            if (err.response){
                console.log(err.response.data)
                console.log(err.response.status)
                console.log(err.response.headers)
            } else {
                console.log(`Error: ${err.message}`)
            }
        }
    }

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "2rem 1.5rem 1rem 2rem", padding: "0 4rem",
                alignItems: "stretch",}}>

                <Typography
                    fontSize="1.2rem" fontFamily="var(--font-family)" lineHeight= "30px"
                    textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                    {`Blog image (${image.split("/")[1]}  ${imgFile.name})`}

                </Typography>

                <TextField id="image" type="file" text={imgFile} accept="image/*" onChange={imageUpload} name="image"
                           sx={{mt: "1rem", mb: "1rem", }}/>

                <TextField id="blog-title" label="Blog Title" value={title}
                           onChange={(e)=> {
                               e.preventDefault()
                               setTitle(e.target.value)
                           }}
                           variant="outlined" sx={{mt: "1rem", mb: "2rem", }} autoFocus={true}/>

                <ReactQuill style={{height: "17rem"}} theme="snow" value={value} onChange={setValue}
                            modules={modules}/>

                <FlexBox sx={{ flexDirection: "row",}}>

                    <Button
                        onClick={submit}
                        variant="outlined" disableElevation
                        sx={{
                            background: "transparent", border: "2px solid #88C0FF", width: "180px",
                            color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                            fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                            borderRadius: "7px", mt: "5rem", mb: "0rem",
                            ':hover': {
                                border: "2px solid #88C0FF", backgroundColor: 'transparent',
                            },
                        }}>
                        Submit post
                    </Button>

                    <Typography
                        fontSize="1.2rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "green" mt= "5rem" mr= "5rem" mb= "0rem">

                        {success}
                    </Typography>

                </FlexBox>
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "2rem 1.5rem 1rem 2rem", padding: "0 4rem",
                alignItems: "stretch",}}>

                <Typography
                    fontSize="1.2rem" fontFamily="var(--font-family)" lineHeight= "30px"
                    textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                    {`Blog image (${image.split("/")[1]}  ${imgFile.name})`}

                </Typography>

                <TextField id="image" type="file" text={imgFile} accept="image/*" onChange={imageUpload} name="image"
                           sx={{mt: "1rem", mb: "1rem", }}/>

                <TextField id="blog-title" label="Blog Title" value={title}
                           onChange={(e)=> {
                               e.preventDefault()
                               setTitle(e.target.value)
                           }}
                           variant="outlined" sx={{mt: "1rem", mb: "2rem", }} autoFocus={true}/>

                <ReactQuill style={{height: "17rem"}} theme="snow" value={value} onChange={setValue}
                            modules={modules}/>

                <FlexBox sx={{ flexDirection: "row",}}>

                    <Button
                        onClick={submit}
                        variant="outlined" disableElevation
                        sx={{
                            background: "transparent", border: "2px solid #88C0FF", width: "180px",
                            color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                            fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                            borderRadius: "7px", mt: "5rem", mb: "0rem",
                            ':hover': {
                                border: "2px solid #88C0FF", backgroundColor: 'transparent',
                            },
                        }}>
                        Submit post
                    </Button>

                    <Typography
                        fontSize="1.2rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "green" mt= "5rem" mr= "5rem" mb= "0rem">

                        {success}
                    </Typography>

                </FlexBox>
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default EditBlog;