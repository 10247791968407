import React, {useEffect} from 'react';
import './marinePlywood.css'
import {FlexBox, PlywoodSolutions} from "components";
import {pine_plywood, birch_plywood, combi_plywood, filmed_pine_plywood, filmed_birch_plywood} from './imports'

const MarinePlywood = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <FlexBox sx={{ flexDirection: "column", m: "2rem 1rem 1rem 2rem", padding: "0 4rem",}}>
            <div className="csf__marine_plywood-heading">
                <h1 className="gradient__text">Marine Plywood</h1>
            </div>

            <PlywoodSolutions title={'Pine Plywood'} img={pine_plywood} description={
                <div>
                    The plywood made of pine wood supplied from South America and Turkey is used in the construction
                    industry, concrete molds, roof coverings and packaging industry.
                </div>
            }/>

            <div className='gray__bg'>
                <PlywoodSolutions title={'Birch Plywood'} img={birch_plywood} description={
                    <div>
                        <div>
                            It is produced from birch trees imported from Russia, Ukraine and northern countries.
                        </div>

                        <div>
                            It is water and moisture resistant.
                            Birch plywood can easily be used in decoration, open space, packaging industry and automotive industry.

                        </div>

                    </div>
                }/>
            </div>

            <PlywoodSolutions title={'Combi Plywood'} img={combi_plywood} description={
                <div>
                    The COMBI Plywood is made from the combination of all the trees used in the production of plywood.
                </div>
            }/>

            <div className='gray__bg'>
                <PlywoodSolutions title={'Filmed Pine Plywood'} img={filmed_pine_plywood} description={
                    <div>
                        <div>
                            Tree type: 100% Pine (Pine)
                        </div>

                        <div>
                            Dimensions: 1250mm x 2500mm
                        </div>

                        <div>Film: Dark Brown 120 gr/m2 Finland Storaenso Brand
                        </div>

                        <div>
                            Glue: WBP Phenolic / 72 hour boiling and water resistant
                        </div>

                        <div>
                            Layer: (18 mm) 13 times Wood + 2 Layers Film,  ( 21 mm ) 15 times Wood + 2 Layers Film
                        </div>

                        <div>
                            Edge Paint: Double layer waterproof acrylic paint
                        </div>

                        <div>
                            Intensity: 650 kg /m3
                        </div>

                        <div>
                            Packaging: Pallets
                        </div>

                    </div>
                }/>
            </div>

            <PlywoodSolutions title={'Filmed Birch Plywood'} img={filmed_birch_plywood} description={
                <div>
                    <div>
                        Tree type: 100% Birch (Birch) – Extra
                    </div>

                    <div>
                        Dimensions: 1250mm x 2500mm
                    </div>

                    <div>Thickness: 18 – 21mm</div>

                    <div>Film: Dark Brown 120 gr/m2 Finland Dynea Brand
                    </div>

                    <div>
                        Glue: WBP Phenolic / 72 hour boiling and water resistant
                    </div>

                    <div>
                        Layer: (18 mm) 13 times Wood + 2 Layers Film,  (21 mm) 15 times Wood + 2 Layers Film
                    </div>

                    <div>
                        Edge Paint: Double layer waterproof acrylic paint
                    </div>

                    <div>
                        Intensity: 720 kg /m3
                    </div>

                    <div>
                        Packaging: Pallets
                    </div>

                </div>
            }/>

        </FlexBox>
    );
};

export default MarinePlywood;