import React from 'react';
import {FlexBox} from "components";
import {Typography} from "@mui/material";
import { formwork, tower_crane, props, beams, marine, fabricated_frame } from './imports';

import { Solution } from "components";
import {useNavigate} from "react-router-dom";
import {comax_tech_sheet} from 'assets/documents/imports';

const Solutions = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const navigate = useNavigate();

    const Desktop = () => {
        return (
            <FlexBox id="solutions" sx={{ flexDirection: "column",  p: "3rem 5.6rem 3rem 6rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                    fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "3rem 2.2rem 3rem 2.5rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "3rem 2.2rem 3rem 2.5rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "3rem 1rem 3rem 1rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "3rem 1rem 3rem 1rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "3rem 1rem 3rem 1rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Our Solutions

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    <Solution img={formwork} title="Forms and scaffolding" clickEvent= {() => { navigate("/formwork-scaffolding") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={tower_crane} title=" Tower cranes" clickEvent= {() => { navigate("/tower-crane") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={props} title="Props" clickEvent= {() => { navigate("/props") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={beams} title="Beams and panels" clickEvent= {() => { navigate("/beam-flex-formwork") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={marine} title="Marine plywood" clickEvent= {() => { navigate("/marine-plywood") }}
                              tech_sheet={comax_tech_sheet}/>
                    <Solution img={fabricated_frame} title="Fabricated frames" clickEvent= {() => { navigate("/fabricated-frame") }}
                              tech_sheet={comax_tech_sheet}/>

                </FlexBox>
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default Solutions;