import React, {useEffect} from 'react';
import { FormworkSolutions } from "containers";
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {sirio1, sirio2} from "containers/formwork-solutions/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";

const SirioDeck = ({isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile,
                       isFoldDevice}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Sirio Deck

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The deck is produced by a single cold-formed sheet. The “TP” mark
                            results from embossing the hook fittings.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Central reinforce with anti-slip indentations without sharp edges
                                </Features>

                                <Features>Passage is facilitated by the support head with three hooks with lowered reinforcing ribs
                                </Features>

                                <Features>Strategic positioning of surface-attaching points, in order to avoid significant bending due to the variation of load
                                </Features>

                                <Features>High stability to longitudinal warpage guaranteed by the continued seam of the profile
                                </Features>

                                <Features>Possibility to build decks up to 250 cm of length on traditional scaffoldings with 180 cm step
                                </Features>
                                <Features>CL6 load, amounting to 600 daN/m², equal to twice the traditional CL4 decks, with 300 daN/m² capacity.
                                </Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>Galvanized sheet surface, 1 mm thick, S250GD, 494 mm wide and L long, with three folds forming three closed profile, which make up the decking level. The sheet creates a 60 mm high profile. The surface of the decking level has anti-sliding indentation, which produce an anti-slip effect.
                                </Features>

                                <Features>no. 2 hook fittings in sheet S250GD, 2.5 mm thick. A support surface and three ribbed hooks are cold-pressed on them, which can be attached with the transoms of the prefabricated frames. Hook fittings are then secured to the walking steel surface with 7 TOX points. Each hook fittings has a safety steel lock S250GD, made of 2.5 mm thick pressed sheet, and slides in a rail placed in the head. This steel lock is formed so that it cannot loosen after being assembled.
                                </Features>

                                <Features>When sliding towards the extremity, the steel lock fastens under the transom with pressure, and prevents the deck to accidentally lift. On the contrary, when it slides inward of the deck, it releases the transom and allows for the removal of the deck. In both cases the position of the steel lock is stable and physical intervention is needed in order to change its position.
                                </Features>

                                <Features>The disposal of contingent standing water is guaranteed by the presence on the surface of holes drilled every 108 mm. Contingent thin ice formations resulting from inevitable partial standing water do not exceed anti-slip indentations, which protrude 2 mm over the deck surface.
                                </Features>

                                <Features>Depending on its length, the deck can achieve 6 class capacity (UNI EN 12811), corresponding to a load of 600 Kg/m².
                                    The deck is produced in several sizes. 75 – 105 – 150 – 180 – 200 – 250-300.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={sirio2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default SirioDeck;