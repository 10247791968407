import {Box} from "@mui/material";

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {

    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <Box component="li"
             onClick={ handleClick }
             sx={{ width: "100%",  cursor: "pointer", p: "0.7rem 1rem 0.7rem 1rem", backgroundColor: activeTab === id ? "#39A2DB" : "",
                 listStyle: "none", textAlign: "center", transition: "all 0.7s", }}>
            { title }
        </Box>
    );
};
export default TabNavItem;