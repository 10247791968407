import React, {useEffect, useState} from 'react';
import {Blog, FlexBox} from "components";
import axios from "axios";
import {Typography} from "@mui/material"
import {SERVER_BASE_API_URL, SERVER_BASE_URL, CLIENT_BASE_URL} from "env"

const AdminAllBlogs = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)

        const fetchBlogPosts = async () => {
            try {
                const { data: { blogs } } = await axios.get(`${SERVER_BASE_API_URL}/blogs`)
                setBlogs(blogs)
                console.log(blogs)
            } catch (err) {
                if (err.response){
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`Error: ${err.message}`)
                }
            }
        }

        fetchBlogPosts()
    }, [])

    const deleteEvent = async (blogs, blog) => {
        try {
            const deleted = await axios.delete(`${SERVER_BASE_API_URL}/blogs/${blog._id}`)

            if (deleted){
                window.location.reload()
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    const Desktop = () => {
        return (
            <FlexBox id="solutions" sx={{ flexDirection: "column",  p: "3rem 5.6rem 3rem 6rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Upload Blogs

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    {blogs.map((blog) => {

                        return <Blog img={`${SERVER_BASE_URL}/${blog.image}`}
                                     title={blog.title} deleteEvent= {() => deleteEvent(blogs, blog)}
                                     editPostUrl={`${CLIENT_BASE_URL}/conformsc-hu75ok0ba-edit-blog?id=${blog._id}`}/>

                    })}

                </FlexBox>
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox id="solutions" sx={{ flexDirection: "column",  p: "3rem 5.6rem 3rem 6rem", alignItems: "stretch"}}>
                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Upload Blogs

                </Typography>

                <FlexBox sx={{ flexDirection: "row", display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gridGap: "2.5rem", alignItems: "flex-start" }}>

                    {blogs.map((blog) => {

                        return <Blog img={`${SERVER_BASE_URL}/${blog.image}`}
                                     title={blog.title} deleteEvent= {() => { }}
                                     editPostUrl={() => { }}/>

                    })}

                </FlexBox>
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>
                Please access admin screens on a large screen
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default AdminAllBlogs;