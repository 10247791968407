import React, {useEffect, useState} from 'react';
import {FlexBox} from "../../components";
import {Box, Button, TextField, Typography} from "@mui/material";
import ReactQuill from "react-quill";
import axios from "axios";
import {SERVER_BASE_API_URL, SERVER_BASE_URL, CLIENT_BASE_URL} from "env"

const ViewBlog = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const params = window.location.search
    const id = new URLSearchParams(params).get('id')

    const [value, setValue] = useState('');
    const [imgFile, setImgFile] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)

        const fetchBlogPost = async () => {
            try {
                const { data: { blog } } = await axios.get(`${SERVER_BASE_API_URL}/blogs/${id}`)

                setTitle(blog.title)
                setValue(blog.body)
                setImage(blog.image)

            } catch (err) {
                if (err.response){
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`Error: ${err.message}`)
                }
            }
        }

        fetchBlogPost()
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "2rem 1.5rem 1rem 2rem", padding: "0 4rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                    marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                    textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "2rem 1.5rem 1rem 2rem", padding: "0 4rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                     marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                     textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "4rem 0rem 1rem 0rem", paddingBottom: "0rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                     marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                     textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "3rem 2rem 1rem 2rem", paddingBottom: "2rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                     marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                     textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "3rem 2rem 1rem 2rem", paddingBottom: "2rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                     marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                     textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  m: "3rem 2rem 1rem 2rem", paddingBottom: "2rem",
                alignItems: "stretch",}}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >
                    {title}
                </Typography>

                <Box component="img"
                     sx={{
                         maxHeight: "400px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", }}
                     src={`${SERVER_BASE_URL}/${image}`}/>

                <Box component="div"
                     marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                     textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)"
                     dangerouslySetInnerHTML={{ __html: value}}/>

            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default ViewBlog;