import React from 'react';

const TowerCranes = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    return (
        <div>
            Tower crane
        </div>
    );
};

export default TowerCranes;