import React, { useState } from 'react';
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { Navbar } from "components";
import {Footer} from "../../containers";

const Layout = ({isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile,
                    isFoldDevice}) => {

    return (
        <Box width="100%" height="100%">
            <Box>
                <Navbar isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                        isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                        isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>

                <Outlet />

                <Footer isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                        isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                        isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>
            </Box>
        </Box>
    );
};

export default Layout;