import React, {useEffect} from 'react';
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {tl_55_6t1, tl_55_6t2, tl_55_6t3} from "scenes/tower-crane/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";
import TowerCrane from "../tower-crane";

const FlatTopTL556T = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with 
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 55 6T</Features>
                                <Features>Max. load (kg): 6,000</Features>
                                <Features>Jib (m.): 55</Features>
                                <Features>Max. capacity (kg): 1,320</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 55 6T</Features>
                                <Features>Max. load (kg): 6,000</Features>
                                <Features>Jib (m.): 55</Features>
                                <Features>Max. capacity (kg): 1,320</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 50 5T</Features>
                                <Features>Max. load (kg): 5,000</Features>
                                <Features>Jib (m.): 50</Features>
                                <Features>Max. capacity (kg): 1,100</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw), 25 HP (18,5 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 50 5T</Features>
                                <Features>Max. load (kg): 5,000</Features>
                                <Features>Jib (m.): 50</Features>
                                <Features>Max. capacity (kg): 1,100</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw), 25 HP (18,5 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 50 5T</Features>
                                <Features>Max. load (kg): 5,000</Features>
                                <Features>Jib (m.): 50</Features>
                                <Features>Max. capacity (kg): 1,100</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw), 25 HP (18,5 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TL 55 6T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The TL 55 6T model is an evolution based on the TL 55 5T. This new 6T version increases the performance of the crane, offering our customers the greatest versatility of their equipment, and the greatest return on their investment.
                            Although the crane has been designed primarily for city construction, being equipped with the new Super-Lift system, a maximum load of 6T, and a 33 HP (24 kW) hoist motor, the crane’s utilization possibilities are considerably increased.
                            This crane is also equipped with the ECO working mode, which allows it to work using 30% less electricity consumption, and it’s supplied from the Saez-factory with a PLC system and “prohibited zone” as standard. The crane can be assembled with operator’s cabin or with a remote control equipped with a display, showing the crane operator all the information relevant to the operation of the crane: tip load, hook height, % of load moment, wind speed, etc.
                            In terms of transport, for a standard tower height, this crane can be transported in 3 HC containers, making it an excellent choice in terms of economy, transport and efficiency.
                            As options available, in case of high working height, this crane can be assembled with
                            both external telescoping and internal climbing, covering all possible customer needs.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TL 50 5T</Features>
                                <Features>Max. load (kg): 5,000</Features>
                                <Features>Jib (m.): 50</Features>
                                <Features>Max. capacity (kg): 1,100</Features>
                                <Features>Motor Hp/kW: 33 HP (24 Kw), 25 HP (18,5 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tl_55_6t2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default FlatTopTL556T;