import React, {useEffect, useState} from 'react';
import {FlexBox, Solution} from "components";
import {Box, Typography} from "@mui/material";
import TabNavItem from "components/tabs/TabNavItem";

import TabContent from "components/tabs/TabContent";
import {
    formwork1, adatto, beam_flex, optimo, circular_pillar, halliform, earth_retaining, o_round, omni_earth, src240,
    aludeck, twentyflex, aluplus, eco, multiportal, props, rooftop, multidirectional, risk_free, pins, coupler, sirio,
    spider1, vega_deck, gd600, fabricated_frame, self_sinking1, tc80_1, tc60_1, multicom_1, multicom_high_capacity_1,
    stages_1, tribune_scaffolding_1, staircases_1, audio_1
} from "./imports";
import {comax_tech_sheet} from "assets/documents/imports";
import {useNavigate} from "react-router-dom";

const FormworkSolutions = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile,
                               isFoldDevice }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("tab1");

    const Desktop = () => {

        return (
            <FlexBox id="solutions" sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                padding: "2rem 5.5rem 3rem 6rem", backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Wall formwork" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Slab formwork" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Scaffolding" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Trench shoring" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Shoring structures" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Structures for events" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu Framework"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 2.3rem 0rem 2.5rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Wall formwork" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Slab formwork" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Scaffolding" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Trench shoring" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Sh. structures" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Str. for events" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu Framework"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 2.3rem 0rem 2.5rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="W. FWork" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S. FWork" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="SCFLDing" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Tr. shoring" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Sh. Strs." id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Str. Evnts" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu F.W"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.4rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="W.F" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.F" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="SCA" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="T.S" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.S" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.E" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu Framework"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.4rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "14px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="W.F" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.F" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="SCA" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="T.S" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.S" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.E" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu Framework"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.2rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Formwork and Scaffolding Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "14px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="W.F" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="S.F" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="SCA" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="T.S" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    {/*<TabNavItem title="S.S" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>*/}
                    {/*<TabNavItem title="S.E" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>*/}

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={formwork1} title="COMAX Formwork" clickEvent= {() => { navigate("/comax-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={adatto} title="Adatto Alu Framework"  clickEvent= {() => { navigate("/adatto-alu-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={beam_flex} title="Beam Flex Formwork"  clickEvent= {() => { navigate("/beam-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={optimo} title="Optimo Formwork"  clickEvent= {() => { navigate("/optimo-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={circular_pillar} title="Circular Pillar F.W"  clickEvent= {() => { navigate("/circular-pillar-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={halliform} title="Haliform Formwork"  clickEvent= {() => { navigate("/halliform-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={earth_retaining} title="MF Earth-retaining"  clickEvent= {() => { navigate("/mf-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={o_round} title="O-ROUND formwork"  clickEvent= {() => { navigate("/o-round-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={omni_earth} title="OMNI Earth-retaining"  clickEvent= {() => { navigate("/omni-earth-retaining") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={src240} title="SRC-240 climbing"  clickEvent= {() => { navigate("/src-240") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={aludeck} title="Aludeck Slab F.W" clickEvent= {() => { navigate("/aludeck-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={twentyflex} title="20 Flex Formwork "  clickEvent= {() => { navigate("/20-flex-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={aluplus} title="Aluplus Slab Formwork"  clickEvent= {() => { navigate("/aluplus-slab") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={eco} title="Eco Formwork" clickEvent= {() => { navigate("/eco-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multiportal} title="Multiportal formwork"  clickEvent= {() => { navigate("/multiportal-formwork") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={props} title="Props"  clickEvent= {() => { navigate("/props") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={rooftop} title="Roof System" clickEvent= {() => { navigate("/roof-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multidirectional} title="Multidir. Scaffolding"  clickEvent= {() => { navigate("/multidirectional-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={fabricated_frame} title="Fabricated Frame"  clickEvent= {() => { navigate("/fabricated-frame") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={risk_free} title="Risk Free Safe Scaffold"  clickEvent= {() => { navigate("/risk-free-safe-scaffold") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={pins} title="Pins Scaffolding System"  clickEvent= {() => { navigate("/pins-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sirio} title="Sirio Deck"  clickEvent= {() => { navigate("/sirio-deck") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={spider1} title="Spider"  clickEvent= {() => { navigate("/spider") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={vega_deck} title="Vega Deck"  clickEvent= {() => { navigate("/vega-deck") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab4" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={self_sinking1} title="MA Self-Sinking System" clickEvent= {() => { navigate("/ma-self-sinking") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={gd600} title="GD600 System"  clickEvent= {() => { navigate("/gd600-system") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={coupler} title="Tube and Coupler"  clickEvent= {() => { navigate("/tube-coupler") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab5" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tc80_1} title="TC80 Shoring Towers" clickEvent= {() => { navigate("/tc80-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tc60_1} title="TC 60 Towers"  clickEvent= {() => { navigate("/tc60-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_1} title="Multicom Shoring towers"  clickEvent= {() => { navigate("/multicom-shoring-towers") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={multicom_high_capacity_1} title="Multicom high-capacity Towers"
                                  clickEvent= {() => { navigate("/multicom-high-capacity-towers") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab6" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={stages_1} title="Stages" clickEvent= {() => { navigate("/stages") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tribune_scaffolding_1} title="Tribune Scaffolding"  clickEvent= {() => { navigate("/tribune-scaffolding") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={staircases_1} title="Staircases and Walkways"  clickEvent= {() => { navigate("/staircases-walkways") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={audio_1} title="Audio/Lighting/Director Tower"
                                  clickEvent= {() => { navigate("/audio-lighting-director-tower") }} tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default FormworkSolutions;