import {Box} from "@mui/material";

const TabContent = ({id, activeTab, children}) => {
    return (
        activeTab === id ? <Box component="div" sx={{ fontSize: "2rem", textAlign: "left"}}>
                { children }
            </Box>
            : null
    );
};

export default TabContent;