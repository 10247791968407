import React from 'react';
import './plywoodSolution.css'

const PlywoodSolutions = ({title, img, description}) => {
    return (
        <div className="csf__plywood_solution">

            <div className="csf__plywood_solution_heading">
                <h1 className="gradient__text">{title}</h1>
            </div>

            <div className="csf_plywood_solutions-content">
                <div className="csf_plywood_solutions-content_image">
                    <img src={img} alt="solution_image" />
                </div>

                <div className="csf_plywood_solutions-content_description">
                    <div>
                        {description}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PlywoodSolutions;