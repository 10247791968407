import React from 'react';
import {FlexBox} from "../../components";
import {Box, Button, Typography} from "@mui/material";
import condor from "assets/brands/condor.png"

import saez from "assets/brands/saez.png"
import {useNavigate} from "react-router-dom";

const About = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const navigate = useNavigate();

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem", backgroundColor: "var(--color-gray-bg)",
                alignItems: "stretch"  }}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 6rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "3rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work with us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 4.5rem 0rem 5rem",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "2rem 3rem 0rem 3rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "3rem 3rem 0rem 3rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "4rem 0rem 1rem 0rem", paddingBottom: "2rem", backgroundColor: "var(--color-gray-bg)",
                alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "3rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work with us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 2.25rem 0rem 1.5rem",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "0rem 1rem 3rem 1rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "0rem 1rem 3rem 1rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "4rem 0rem 1rem 0rem", paddingBottom: "0rem", backgroundColor: "var(--color-gray-bg)",
                alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 2.5rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "3rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work with us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "5rem 2.25rem 0rem 2.5rem", alignItems: "flex-start"}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "3rem 1rem 0rem 1rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "4rem 1rem 5rem 1rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "3rem 0rem 1rem 0rem", paddingBottom: "2rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "2rem 2rem 0rem 1.5rem", alignItems: "stretch",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "2rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "1rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work with us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "4rem 2.25rem 0rem 1.5rem", alignItems: "flex-start"}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "3rem 0rem 0rem 0rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "4rem 0rem 2rem 0rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "3rem 0rem 1rem 0rem", paddingBottom: "2rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "2rem 2rem 0rem 1.5rem", alignItems: "stretch",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "2rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "1rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work with us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "4rem 2.25rem 0rem 1.5rem", alignItems: "flex-start"}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "3rem 0rem 0rem 0rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "4rem 0rem 2rem 0rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "3rem 0rem 1rem 0rem", paddingBottom: "2rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "2rem 2rem 0rem 1.5rem", alignItems: "stretch",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                        Who are We?

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="0.9rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Construction Formwork and Scaffold Limited is a leading provider of formwork, scaffold and tower crane solutions for
                        the construction industry. We are the exclusive distribution partner for CONDOR, and SAEZ CRANES in
                        Nigeria.

                        <Typography
                            marginTop="2rem" fontSize="0.9rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Our products are manufactured to the highest standards and are designed to provide maximum safety and
                            efficiency for the construction industry.
                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="0.9rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The CFSL team is always available to deliver unparalleled service and support to our clients. We strive
                            to make every project a success, and we are dedicated to helping you achieve your goals.
                        </Typography>

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "2rem",}}>
                        <Button
                            onClick={() => { navigate("/projects") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Projects
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "1rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Work
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "4rem 2.25rem 0rem 1.5rem", alignItems: "flex-start"}}>
                    <Typography
                        fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                        Exclusive Distribution Partner for

                    </Typography>

                    <a href='https://condorformwork.com/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%",  cursor: "pointer", p: "3rem 0rem 0rem 0rem" }}
                             src={condor}/>
                    </a>

                    <a href='https://gruassaez.com/en/saez-group/' target="_blank">
                        <Box component="img"
                             sx={{ width: "100%", cursor: "pointer", p: "4rem 0rem 2rem 0rem" }}
                             src={saez}/>
                    </a>

                </FlexBox>

            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default About;