import React, {useEffect} from 'react';
import { FormworkSolutions } from "containers";
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {prop1, prop2} from "containers/formwork-solutions/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";

const Props = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="1rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Props

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                ECON
                            </Typography>

                            <Box component="ul">
                                <Features>ECON prop is easy to handle, and it has a minimum capacity of 20 kN</Features>

                                <Features>Available in three size (ECON300; ECON 350) it allow to work on different
                                    heights from 1.80m to 4.00m.
                                </Features>
                                <Features>Available in hot-dip galvanized, electro-galvanized and painted version.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP10
                            </Typography>

                            <Box component="ul">
                                <Features>CEP –CONDOR European Props comply with the European standard
                                </Features>

                                <Features>The CEP10 prop is easy to handle and light with a 10 kN minimum capacity.</Features>
                                <Features>Four types of props (CEP10-250; CEP10-300; CEP10-350; CEP10-400) suitable to
                                    cover heights from 1,50m to 4,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP20
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP20 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>Five types of props (CEP20-300; CEP20-350; CEP20-400; CEP20-450; CEP20-500)
                                    suitable to cover heights from 1,80m to 5,00m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                CEP30
                            </Typography>

                            <Box component="ul">
                                <Features>The CEP30 prop is easy to handle and light with a 30 kN minimum capacity.</Features>

                                <Features>Three types of props (CEP30-300; CEP30-350; CEP30-400) suitable to cover
                                    heights from 1,80m to 4,00 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                D55
                            </Typography>

                            <Box component="ul">
                                <Features>The D55 prop is easy to handle and light with a 20 kN minimum capacity.</Features>

                                <Features>It allows you to cover heights ranging from 3.10 m to 5.50 m.</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Props ALU CP
                            </Typography>

                            <Box component="ul">
                                <Features>ALU CP props have a variable height from 1.80m to 6.00m.</Features>

                                <Features>Light weight thanks to aluminum profiles, but with extremely high load
                                    capacity. The specific thread with self-cleaning nut ensures continuous
                                    cleaning and allows a fast regulation in the construction site.</Features>

                                <Features>They can be used singularly up to 6m or as standards in shoring tower for higher
                                    heights.</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop1}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={prop2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default Props;