import React, {useState} from 'react';
import { AppBar, Box, Button, Toolbar, List, ListItem, } from "@mui/material";
import {FlexBox, NavItem} from "../index";

import logo  from "assets/logo/logo.png"
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


const Navbar = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {

    const navigationItems = [
        { text: "Home", navigateTo: "/home" },
        { text: "Solutions", navigateTo: "/home" },
        // { text: "Projects", navigateTo: "/projects" },
        { text: "Company", navigateTo: "/home" },
        { text: "Our Blog", navigateTo: "/blog" },
        { text: "Reach us", navigateTo: "/contact" },
    ];

    const solutionItems = [
        { text: "Formwork & Scaffolding", moveTo: "/formwork-scaffolding" },
        { text: "Tower Cranes", moveTo: "/tower-crane" },
        { text: "Props", moveTo: "/props" },
        { text: "Beams & Panels", moveTo: "/beam-flex-formwork" },
    ];

    const formworkItems = [
        { text: "Wall Formwork", advanceTo: "/comax-formwork" },
        { text: "Slab Formwork", advanceTo: "/aludeck-slab" },
        { text: "Scaffolding", advanceTo: "/roof-system" },
        { text: "Trench Shoring", advanceTo: "/ma-self-sinking" },
        { text: "Shoring Structures", advanceTo: "/tc80-shoring-towers" },
        { text: "Structures for Events", advanceTo: "/stages" },
    ];

    const companyItems = [
        { text: "Who We Are", forwardTo: "/home" },
        { text: "Work With Us", forwardTo: "/contact" },
    ];

    const navigate = useNavigate();
    const [toggleSolutions, setToggleSolutions] = useState(false)
    const [toggleFormwork, setToggleFormwork] = useState(false)
    const [toggleCompanyItems, setToggleCompanyItems] = useState(false)

    const [toggleMenu, setToggleMenu] = useState(false)

    const Menu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0 4rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "100px", height: "81px", cursor: "pointer", m: "0.5rem" }}
                         src={logo}/>

                    <FlexBox gap="1.8rem">

                        {navigationItems.map(({text, navigateTo}) => {

                            if (text === "Solutions") {
                                return (
                                    <NavItem
                                             onMouseOver={() => {
                                                 setToggleSolutions(true)
                                                 setToggleCompanyItems(false)
                                             }}>

                                        {text} {<KeyboardArrowDownIcon />}

                                        {toggleSolutions &&
                                            (
                                                <List
                                                    sx={{
                                                        position: "absolute", backgroundColor: "#ffffff", marginTop: "13rem",
                                                        minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        opacity: "0.9"
                                                    }}>{

                                                    solutionItems.map(({text, moveTo}) => {

                                                        if (text === "Formwork & Scaffolding") {
                                                            return (
                                                                <ListItem key={text}
                                                                          sx={{
                                                                              ':hover': {
                                                                                  backgroundColor: '#fafafa',
                                                                              },}}
                                                                          onMouseOver={(e) => {
                                                                              setToggleFormwork(true)
                                                                          }} >

                                                                    {text} {<KeyboardArrowRightIcon />}

                                                                    {toggleFormwork && (
                                                                        <List
                                                                            sx={{
                                                                                position: "absolute", backgroundColor: "#ffffff", marginTop: "13rem",
                                                                                marginLeft: "13.5rem", minWidth: "200px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                                                opacity: "1"
                                                                            }}>{

                                                                            formworkItems.map(({text, advanceTo}) => {

                                                                                return (
                                                                                    <ListItem key={text}
                                                                                              sx={{
                                                                                                  ':hover': {
                                                                                                      backgroundColor: '#fafafa',
                                                                                                  },}}
                                                                                              onMouseLeave={() => {
                                                                                                  setToggleFormwork(false)
                                                                                              }}
                                                                                              onClick={() => { navigate(advanceTo) }}

                                                                                    >{text}</ListItem>
                                                                                )})}
                                                                        </List>
                                                                    )}

                                                                </ListItem>
                                                            )
                                                        }

                                                        return (
                                                            <ListItem key={text}
                                                                      sx={{
                                                                          ':hover': {
                                                                              backgroundColor: '#fafafa',
                                                                          },}}
                                                                      onMouseEnter={() => { setToggleFormwork(false) }}
                                                                      onMouseLeave={() => { setToggleSolutions(false) }}
                                                                      onClick={() => { navigate(moveTo) }}
                                                            >{text}</ListItem>
                                                        )})}
                                                </List>
                                            )
                                        }

                                    </NavItem>
                                )
                            }

                            if (text === "Company") {
                                return (
                                    <NavItem
                                             onMouseOver={() => {
                                                 setToggleCompanyItems(true)
                                                 setToggleSolutions(false)
                                                 setToggleFormwork(false)
                                             }}>

                                        {text} {<KeyboardArrowDownIcon />}

                                        {toggleCompanyItems &&
                                            (
                                                <List
                                                    sx={{
                                                        position: "absolute", backgroundColor: "#ffffff", marginTop: "8rem",
                                                        minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        opacity: "0.9"
                                                    }}>{

                                                    companyItems.map(({text, forwardTo}) => {

                                                        return (
                                                            <ListItem key={text}
                                                                      sx={{
                                                                          ':hover': {
                                                                              backgroundColor: '#fafafa',
                                                                          },}}
                                                                      onMouseLeave={() => { setToggleCompanyItems(false) }}
                                                                      onClick={() => { navigate(forwardTo) }}
                                                            >{text}</ListItem>
                                                        )})}
                                                </List>
                                            )
                                        }

                                    </NavItem> )
                            }

                            return (
                                <NavItem onClick={() => { navigate(navigateTo) }}
                                         onMouseEnter={() => {
                                             setToggleSolutions(false)
                                             setToggleFormwork(false)
                                             setToggleCompanyItems(false)
                                         }}>
                                    {text}
                                </NavItem> )
                        })}

                        <Button
                            variant="contained" disableElevation
                            onClick={() => { navigate("/contact") }}
                            sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "14px",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    backgroundColor: '#D31717',
                                },
                            }}>
                            Request a Quote
                        </Button>
                    </FlexBox>
                </Toolbar>
            </AppBar>
        )
    }

    const SmallDesktopMenu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0 0.5rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "100px", height: "81px", cursor: "pointer", m: "0.5rem" }}
                         src={logo}/>

                    <FlexBox gap="1.8rem">

                        {navigationItems.map(({text, navigateTo}) => {

                            if (text === "Solutions") {
                                return (
                                    <NavItem
                                             onMouseOver={() => {
                                                 setToggleSolutions(true)
                                                 setToggleCompanyItems(false)
                                             }}>

                                        {text} {<KeyboardArrowDownIcon />}

                                        {toggleSolutions &&
                                            (
                                                <List
                                                    sx={{
                                                        position: "absolute", backgroundColor: "#ffffff", marginTop: "13rem",
                                                        minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        opacity: "0.9"
                                                    }}>{

                                                    solutionItems.map(({text, moveTo}) => {

                                                        if (text === "Formwork & Scaffolding") {
                                                            return (
                                                                <ListItem key={text}
                                                                          sx={{
                                                                              ':hover': {
                                                                                  backgroundColor: '#fafafa',
                                                                              },}}
                                                                          onMouseOver={(e) => {
                                                                              setToggleFormwork(true)
                                                                          }}>

                                                                    {text} {<KeyboardArrowRightIcon />}

                                                                    {toggleFormwork && (
                                                                        <List
                                                                            sx={{
                                                                                position: "absolute", backgroundColor: "#ffffff", marginTop: "13rem",
                                                                                marginLeft: "13.5rem", minWidth: "200px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                                                opacity: "1"
                                                                            }}>{

                                                                            formworkItems.map(({text, advanceTo}) => {

                                                                                return (
                                                                                    <ListItem key={text}
                                                                                              sx={{
                                                                                                  ':hover': {
                                                                                                      backgroundColor: '#fafafa',
                                                                                                  },}}
                                                                                              onMouseLeave={() => {
                                                                                                  setToggleFormwork(false)
                                                                                              }}
                                                                                              onClick={() => { navigate(advanceTo) }}

                                                                                    >{text}</ListItem>
                                                                                )})}
                                                                        </List>
                                                                    )}

                                                                </ListItem>
                                                            )
                                                        }

                                                        return (
                                                            <ListItem key={text}
                                                                      sx={{
                                                                          ':hover': {
                                                                              backgroundColor: '#fafafa',
                                                                          },}}
                                                                      onMouseEnter={() => { setToggleFormwork(false) }}
                                                                      onMouseLeave={() => { setToggleSolutions(false) }}
                                                                      onClick={() => { navigate(moveTo) }}
                                                            >{text}</ListItem>
                                                        )})}
                                                </List>
                                            )
                                        }

                                    </NavItem> )
                            }

                            if (text === "Company") {
                                return (
                                    <NavItem
                                             onMouseOver={() => {
                                                 setToggleCompanyItems(true)
                                                 setToggleSolutions(false)
                                                 setToggleFormwork(false)
                                             }}>

                                        {text} {<KeyboardArrowDownIcon />}

                                        {toggleCompanyItems &&
                                            (
                                                <List
                                                    sx={{
                                                        position: "absolute", backgroundColor: "#ffffff", marginTop: "8rem",
                                                        minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                                        opacity: "0.9"
                                                    }}>{

                                                    companyItems.map(({text, forwardTo}) => {

                                                        return (
                                                            <ListItem key={text}
                                                                      sx={{
                                                                          ':hover': {
                                                                              backgroundColor: '#fafafa',
                                                                          },}}
                                                                      onMouseLeave={() => { setToggleCompanyItems(false) }}
                                                                      onClick={() => { navigate(forwardTo) }}
                                                            >{text}</ListItem>
                                                        )})}
                                                </List>
                                            )
                                        }

                                    </NavItem> )
                            }

                            return (
                                <NavItem onClick={() => { navigate(navigateTo) }}
                                         onMouseEnter={() => {
                                             setToggleSolutions(false)
                                             setToggleFormwork(false)
                                             setToggleCompanyItems(false)
                                         }}>
                                    {text}
                                </NavItem> )
                        })}

                        <Button
                            variant="contained" disableElevation
                            sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "14px",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    backgroundColor: '#D31717',
                                },
                            }}>
                            Request a Quote
                        </Button>
                    </FlexBox>
                </Toolbar>
            </AppBar>
        )
    }

    const TabletMenu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0 0.5rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "85px", height: "71px", cursor: "pointer", mt: "1rem", mb: "1rem" }}
                         src={logo}/>

                    <FlexBox gap="1.8rem">
                        <Button
                            variant="contained" disableElevation
                            sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "14px",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                ':hover': {
                                    backgroundColor: '#D31717',
                                },
                            }}>
                            Request a Quote
                        </Button>

                        {toggleMenu
                            ? <CloseIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(false)} />
                            : <MenuIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(true )} />
                        }

                        {toggleMenu && (
                            <FlexBox
                                sx={{
                                    position: "absolute", backgroundColor: "#ffffff", p: "1rem", borderRadius: "5px",
                                    marginTop: "23rem", marginLeft: "2rem", minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                    opacity: "O.8", alignItems: "flex-start", flexDirection: "column"}} gap="1.8rem">

                                {navigationItems.map(({text, navigateTo}) => {

                                    return (
                                        <NavItem onClick={() => { navigate(navigateTo) }}
                                                 sx={{
                                                     ':hover': {
                                                         backgroundColor: '#fafafa',
                                                     }}}
                                                 onMouseEnter={() => {
                                                     setToggleMenu(false)
                                                 }}>
                                            {text}
                                        </NavItem>
                                    )
                                })}

                            </FlexBox>
                        )}
                    </FlexBox>
                </Toolbar>
            </AppBar>

        )
    }

    const MobileMenu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0 0.1rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "75px", height: "61px", cursor: "pointer", mt: "0.5rem", mb: "0.5rem" }}
                         src={logo}/>

                    {toggleMenu
                        ? <CloseIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(false)} />
                        : <MenuIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(true )} />
                    }

                    {toggleMenu && (
                        <FlexBox
                            sx={{
                                position: "absolute", backgroundColor: "#ffffff", p: "1rem", borderRadius: "5px",
                                marginTop: "25.5rem", marginLeft: "11rem", minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                opacity: "0.95", alignItems: "flex-start", flexDirection: "column"}} gap="1.8rem">

                            {navigationItems.map(({text, navigateTo}) => {

                                return (
                                    <NavItem onClick={() => { navigate(navigateTo) }}
                                             sx={{
                                                 ':hover': {
                                                     backgroundColor: '#fafafa',
                                                 }}}
                                             onMouseEnter={() => {
                                                 setToggleMenu(false)
                                             }}>
                                        {text}
                                    </NavItem>
                                )
                            })}

                            <Button
                                variant="contained" disableElevation
                                sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "14px",
                                    fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                    ':hover': {
                                        backgroundColor: '#D31717',
                                    },
                                }}>
                                Request a Quote
                            </Button>
                        </FlexBox>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    const SmallMobileMenu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0 0.1rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "75px", height: "61px", cursor: "pointer", mt: "0.5rem", mb: "0.5rem" }}
                         src={logo}/>

                    {toggleMenu
                        ? <CloseIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(false)} />
                        : <MenuIcon sx={{ fontSize: 27 }} onClick={() => setToggleMenu(true )} />
                    }

                    {toggleMenu && (
                        <FlexBox
                            sx={{
                                position: "absolute", backgroundColor: "#ffffff", p: "1rem", borderRadius: "5px",
                                marginTop: "25.5rem", marginLeft: "9.5rem", minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                opacity: "0.95", alignItems: "flex-start", flexDirection: "column"}} gap="1.8rem">

                            {navigationItems.map(({text, navigateTo}) => {

                                return (
                                    <NavItem onClick={() => { navigate(navigateTo) }}
                                             sx={{ fontSize: "13px",
                                                 ':hover': {
                                                     backgroundColor: '#fafafa',
                                                 }}}
                                             onMouseEnter={() => {
                                                 setToggleMenu(false)
                                             }}>
                                        {text}
                                    </NavItem>
                                )
                            })}

                            <Button
                                variant="contained" disableElevation
                                sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "13px",
                                    fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                    ':hover': {
                                        backgroundColor: '#D31717',
                                    },
                                }}>
                                Request a Quote
                            </Button>
                        </FlexBox>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    const FoldDeviceMenu = () => {
        return (
            <AppBar sx={{
                position: "sticky",  background: "white", boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .02)",
                opacity: "0.95", padding: "0rem" }}>

                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box component="img"
                         sx={{ width: "75px", height: "61px", cursor: "pointer", mt: "0.5rem", mb: "0.5rem" }}
                         src={logo}/>

                    {toggleMenu
                        ? <CloseIcon sx={{ fontSize: 26 }} onClick={() => setToggleMenu(false)} />
                        : <MenuIcon sx={{ fontSize: 26 }} onClick={() => setToggleMenu(true )} />
                    }

                    {toggleMenu && (
                        <FlexBox
                            sx={{
                                position: "absolute", backgroundColor: "#ffffff", p: "1rem", borderRadius: "5px",
                                marginTop: "24rem", marginLeft: "5rem", minWidth: "170px", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                                opacity: "0.95", alignItems: "flex-start", flexDirection: "column"}} gap="1.8rem">

                            {navigationItems.map(({text, navigateTo}) => {

                                return (
                                    <NavItem onClick={() => { navigate(navigateTo) }}
                                             sx={{ fontSize: "12px",
                                                 ':hover': {
                                                     backgroundColor: '#fafafa',
                                                 }}}
                                             onMouseEnter={() => {
                                                 setToggleMenu(false)
                                             }}>
                                        {text}
                                    </NavItem>
                                )
                            })}

                            <Button
                                variant="contained" disableElevation
                                sx={{ backgroundColor: "#E93434", color: "white", textTransform: "none", fontSize: "12px",
                                    fontWeight: "500", fontFamily: "var(--font-family)", p: "0.2rem 1rem",
                                    ':hover': {
                                        backgroundColor: '#D31717',
                                    },
                                }}>
                                Request a Quote
                            </Button>
                        </FlexBox>
                    )}
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Menu />
            )}

            {isNormalDesktop && (
                <Menu />
            )}

            {isSmallDesktop && (
                <SmallDesktopMenu />
            )}

            {isTablet && (
                <TabletMenu />
            )}

            {isMobile && (
                <MobileMenu />
            )}

            {isSmallMobile && (
                <SmallMobileMenu />
            )}

            {isFoldDevice && (
                <FoldDeviceMenu />
            )}
        </>
    );
};

export default Navbar;