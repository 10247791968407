import React from 'react';
import { FlexBox } from "components";
import {Box, Button, Typography} from "@mui/material";
import intro_video from "assets/videos/intro_video.mp4"
import {useNavigate} from "react-router-dom";

const Header = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const navigate = useNavigate();

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 5rem 0rem 0rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "3rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.6", flexDirection: "row",  p: "0rem 0.5rem 0rem 5rem",}}>
                    <Video url={intro_video} autoplay={true} width="470px"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 0.5rem"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="1.5rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        marginTop="1.5rem" fontSize="1.1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "2rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "0.6", flexDirection: "row",  p: "0rem 0.5rem 0rem 0rem",}}>
                    <Video url={intro_video} autoplay={true} width="470px"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "2rem 1rem 1rem 2rem", padding: "0 0.5rem"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 0rem", alignItems: "flex-start",}}>
                    <Typography
                        fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        marginTop="1.5rem" fontSize="1.1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", mt: "2rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "180px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "180px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "1", flexDirection: "row",  p: "3rem 0.5rem 0rem 0rem", alignItems: "stretch",}}>
                    <Video url={intro_video} autoplay={true} width="100%"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "1rem 0rem 0rem 0rem", padding: "0 0.1rem",
                alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", m: "0rem 0rem 0rem 0rem", alignItems: "stretch"}}>
                    <Typography
                        m= "0rem 0.5rem 0rem 1rem"
                        fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        m= "1.5rem 1.5rem 0rem 1rem"
                        fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", m: "2rem 1.5rem 0rem 1rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1.5rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "0.5rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1.5rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "1", flexDirection: "row",  p: "2rem 1.5rem 0rem 1.2rem", alignItems: "stretch",}}>
                    <Video url={intro_video} autoplay={true} width="100%"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "1rem 0rem 0rem 0rem", padding: "0 0.1rem",
                alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", m: "0rem 0rem 0rem 0rem", alignItems: "stretch"}}>
                    <Typography
                        m= "0rem 0.5rem 0rem 1rem"
                        fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        m= "1.5rem 1.5rem 0rem 1rem"
                        fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", m: "2rem 1.5rem 0rem 1rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Our solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "0.5rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact us
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "1", flexDirection: "row",  p: "2rem 1.5rem 0rem 1.2rem", alignItems: "stretch",}}>
                    <Video url={intro_video} autoplay={true} width="100%"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "1rem 0rem 0rem 0rem", padding: "0 0rem",
                alignItems: "stretch"}}>

                <FlexBox sx={{ flex: "1", flexDirection: "column", m: "0rem 0rem 0rem 0rem", alignItems: "stretch"}}>
                    <Typography
                        m= "0rem 0.5rem 0rem 1rem"
                        fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                        Leading Provider of Formwork, Scaffold & Tower Crane Solutions

                    </Typography>

                    <Typography
                        m= "1.5rem 1.5rem 0rem 1rem"
                        fontSize="0.9rem" fontFamily="var(--font-family)" lineHeight= "30px"
                        textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        At Construction formwork and scaffold, our products are manufactured to the highest standards and
                        are designed to provide maximum safety and efficiency for the construction industry.

                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", m: "2rem 1.5rem 0rem 1rem",}}>
                        <Button
                            onClick={() => { navigate("/formwork-scaffolding") }}
                            variant="outlined" disableElevation
                            sx={{
                                background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                },
                            }}>
                            Solutions
                        </Button>

                        <Button
                            onClick={() => { navigate("/contact") }}
                            variant="contained" disableElevation
                            sx={{
                                ml: "0.5rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                borderRadius: "7px",
                                ':hover': {
                                    border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                },
                            }}>
                            Contact
                        </Button>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flex: "1", flexDirection: "row",  p: "2rem 1.5rem 0rem 1.2rem", alignItems: "stretch",}}>
                    <Video url={intro_video} autoplay={true} width="100%"/>
                </FlexBox>

            </FlexBox>
        )
    }

    const Video = ({url, autoplay, width}) => {
        return (
            <Box>
                <video width={width} controls autoPlay={autoplay} muted={autoplay} loop={autoplay}>
                    <source src={url} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                </video>
            </Box>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default Header;