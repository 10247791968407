import React from 'react';
import {FlexBox} from "components";
import logo  from "assets/logo/logo.png"
import {Box, Typography} from "@mui/material";

import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import {LinkedIn} from "@mui/icons-material";


const Footer = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "row", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "200px", p: "3rem 0rem 0rem 6rem" }}
                         src={logo}/>

                    <Typography
                        marginTop="2rem" p= "3rem 0rem 0rem 1rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 5rem 0rem 0rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>


            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "row", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "160px", p: "3rem 0rem 0rem 3rem" }}
                         src={logo}/>

                    <Typography
                        marginTop="2rem" p= "3rem 0rem 0rem 1rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 1rem 0rem 0rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>


            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "row", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "row", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "160px", p: "3rem 0rem 0rem 3rem" }}
                         src={logo}/>

                    <Typography
                        marginTop="2rem" p= "3rem 0rem 0rem 1rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 1.5rem 0rem 0rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>


            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 1rem 0rem 1.5rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "140px", p: "3rem 0rem 0rem 1.5rem" }}
                         src={logo} />

                    <Typography
                        marginTop="2rem" p= "0rem 1rem 0rem 1.5rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 1rem 0rem 1.5rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "140px", p: "3rem 0rem 0rem 1.5rem" }}
                         src={logo} />

                    <Typography
                        marginTop="2rem" p= "0rem 1rem 0rem 1.5rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column", m: "4rem 0rem 1rem 0rem", paddingBottom: "4rem",
                backgroundColor: "var(--color-gray-bg)", alignItems: "stretch"}}>

                <FlexBox sx={{ flexDirection: "column", m:"0rem 1rem 0rem 1.5rem",
                    alignItems: "flex-start" }}>

                    <Typography
                        marginTop="2rem" p= "1rem 6rem 1rem 0.3rem"  fontWeight= "500" fontSize= "19px" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        Socials
                    </Typography>

                    <FlexBox sx={{ flexDirection: "row", alignItems: "flex-start" }}>
                        <a href="https://web.facebook.com/people/Construction-Formwork-and-Scaffold-Limited/100088426884433/?mibextid=LQQJ4d"
                           target='_blank'>
                            <Facebook sx={{ fontSize: 40, color: "#3B5998", m:"0rem 1rem 0rem 0rem", }} />
                        </a>

                        <a href="#">
                            <Twitter sx={{ fontSize: 40, color: "#55ACEE", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.instagram.com/conformscaff/" target='_blank'>
                            <Instagram sx={{ fontSize: 40, color: "#DB4371", m:"0rem 1rem 0rem 0rem",  }} />
                        </a>

                        <a href="https://www.linkedin.com/company/construction-formwork-and-scaffold-limited/" target='_blank'>
                            <LinkedIn sx={{ fontSize: 40, color: "#0179B5", m:"0rem 1rem 0rem 0rem",   }} />
                        </a>

                    </FlexBox>

                </FlexBox>

                <FlexBox sx={{ flexDirection: "column", p:"5",
                    alignItems: "flex-start" }}>

                    <Box component="img"
                         sx={{ width: "140px", p: "3rem 0rem 0rem 1.5rem" }}
                         src={logo} />

                    <Typography
                        marginTop="2rem" p= "0rem 1rem 0rem 1.5rem" fontSize="0.9rem" fontFamily="var(--font-family)"
                        lineHeight= "30px" textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                        © 2023 CFSL. All Rights Reserved.
                    </Typography>

                </FlexBox>

            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default Footer;