const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const Features = styled(Box)({
    component: "li",
    display: "flex",
    marginTop: "0.1rem",
    fontSize: "1rem",
    fontFamily: "var(--font-family)",
    lineHeight: "30px",
    textAlign: "justify",
    textJustify: "inter-word",
    color: "var(--color-text)",
    alignItems: "flex-start"
});

export default Features;