import React, {useState} from 'react';
import {FlexBox} from "../index";
import {Box, Button} from "@mui/material";

const Blog = ({img, title, deleteEvent, editPostUrl}) => {
    const [enter, setEnter] = useState(false)

    return (
        <FlexBox
            sx={{
                flexDirection: "column", backgroundColor: "var(--color-bg)", border: "none",
                boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .2)", borderRadius: "0.25rem",
                overflow: "hidden", alignItems: "stretch"}}>

            <FlexBox
                onMouseEnter = {() => {setEnter(true)}}
                onMouseLeave = {() => {setEnter(false)}}

                sx={{
                    overflow: "hidden", position: "relative",
                    ':hover': {
                        opacity: "0.7"
                    } }}>

                <Box component="img"
                     sx={{
                         maxHeight: "300px", width: "100%", aspectRatio: "16 / 9", objectFit: "cover",
                         objectPosition: "center", transition: "200ms transform ease-in-out",
                         ':hover': {
                             transform: "scale(1.05)",
                         }, }}
                     src={img}/>

                <a href={editPostUrl} target='_blank'>
                    <Button
                        variant="contained" disableElevation
                        sx={{
                            minHeight: "37px", m: "1.3rem 0.5rem 1rem 0rem", fontFamily: "var(--font-family)",
                            fontWeight: "400", fontSize: "1rem", lineHeight: "25px", backgroundColor: "#88C0FF",
                            border: "2px solid #88C0FF", p: "0 1rem", color: "#ffffff", cursor: "pointer",
                            outline: "none", borderRadius: "7px", transition: ".5s ease", opacity: enter ? "1" : "0",
                            position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)",
                            msTransform: "translate(-50%, -50%)", textTransform: "none", width: "210px",
                            ':hover': {
                                border: "2px solid #88C0FF", backgroundColor: '#88C0FF', opacity: "1"
                            },
                        }}>
                        Edit blog post
                    </Button>
                </a>

            </FlexBox>

            <FlexBox sx={{ flexDirection: "row", }}>
                <FlexBox
                    sx={{
                        flex: "1", p: "1.5rem 0.5rem 1rem 0.5rem", fontFamily: "var(--font-family)",
                        fontWeight: "500", fontSize: "16px", lineHeight: "25px",
                        color: "var(--color-text)" }}>
                    {title.substring(0, 19)}
                </FlexBox>

                <Button
                    variant="contained" disableElevation
                    onClick={deleteEvent}
                    sx={{
                        flex: "0.75", minHeight: "37px", margin: "1.3rem 0.5rem 1rem 0rem",
                        fontFamily: "var(--font-family)", fontWeight: "400",
                        fontSize: "0.9rem", lineHeight: "25px", background: "transparent",
                        border: "2px solid #88C0FF", p: "0 0.4rem", color: "var(--color-text)",
                        cursor: "pointer", outline: "none", borderRadius: "7px", textTransform: "none",
                        ':hover': {
                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF', opacity: "1"
                        },
                    }}>
                    Delete post
                </Button>

            </FlexBox>

        </FlexBox>
    );
};

export default Blog;