import React, {useState, useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import './contact.css'
import  {FlexBox, FormComponent} from "components";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [values, setValues] = useState({
        company: "",
        fullName: "",
        email: "",
        action: "",
        message: "",
    })
    const [errors, setErrors] = useState({});

    const form = useRef();

    const inputs = [
        {
            id: 1,
            name: "company",
            type: "text",
            placeholder: "Company name",
            errorMessage: "Please enter your company name",
            label: "Company",
            required: true,
        },
        {
            id: 2,
            name: "fullName",
            type: "text",
            placeholder: "Full name",
            errorMessage: "Please enter your full name",
            label: "Full name",
            required: true,
        },
        {
            id: 3,
            name: "email",
            type: "email",
            placeholder: "Email",
            errorMessage: "Please enter a valid email address!",
            label: "Email",
            //pattern: `/^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/`,
            required: true,
        },
        {
            id: 4,
            name: "action",
            type: "select",
            placeholder: "",
            label: "What would you like to do?",
            options: [
                {
                    id: 1,
                    type: "option",
                    value: "Request a quote",
                    content: "Request a quote"
                },
                {
                    id: 2,
                    type: "option",
                    value: "Work with CFSL",
                    content: "Work with CFSL"
                }
            ],
            required: true,
        },
        {
            id: 5,
            name: "message",
            type: "textarea",
            rows: "4",
            placeholder: "Message",
            errorMessage: "Please enter a valid email address!",
            label: "Message",
            required: true,
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};

        if (!isEmail(values.email)) {
            errors.email = "Please enter a valid email address!";
        }

        setErrors(errors);

        if (!Object.keys(errors).length) {
            //alert(JSON.stringify(values, null, 2));
        }

        emailjs.sendForm('service_28su909', 'template_y18p55z', form.current, 'coLkdk9vpnSaa_MnN')
            .then((result) => {
                console.log(result.text);
                setValues({
                    company: ".",
                    fullName: ".",
                    email: ".",
                    action: "",
                    message: ".",});
            }, (error) => {
                console.log(error.text);
            });

        errors.success = "Thank you for contacting CFSL. Your message has been delivered";
    };

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem",}}>
            <div className="csf__contact-form">
                <div className="csf__contact-heading">
                    <h1 className="gradient__text">We'd love to hear from you</h1>
                </div>

                <form ref={form} onSubmit={handleSubmit}>
                    {inputs.map((input) => (
                        <FormComponent key={input.id} {...input} value={values[input.name]} onChange={onChange}
                        />
                    ))}

                    <button>Submit</button>

                    <br />
                    {Object.entries(errors).map(([key, error]) => (
                        <span
                            key={`${key}: ${error}`}
                            style={{
                                fontWeight: "bold",
                                color: "red"
                            }}>{key.toLocaleUpperCase()}: {error}
                        </span>
                    ))}
                </form>

            </div>

            <div className='csf__details'>
                <div className='csf__map'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.7189833665616!2d3.447303514882768!3d6.430135595347805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf51ad1e7fb83%3A0xbb0eb25127f72107!2sPalace%20Rd%20%26%20Abisogun%20Rd%2C%20Maroko%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1676044334395!5m2!1sen!2sng"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className='csf__address'>
                    <div className="csf__contact-heading">
                        <h6 className="gradient__text">Address: 2 Abisogun Road, off Palace road Oniru</h6>
                    </div>

                    <div className="csf__contact-heading">
                        <h6 className="gradient__text">Email addresses: info@conformscaff.com, sales@conformscaff.com</h6>
                    </div>

                    <div className="csf__contact-heading">
                        <h6 className="gradient__text">Phone number: +234-700 237 5266</h6>
                    </div>

                    <div className="csf__contact-heading">
                        <h6 className="gradient__text">Website: www.conformscaff.com</h6>
                    </div>
                </div>
            </div>
        </FlexBox>
    );
};

export default Contact;