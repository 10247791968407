import React, {useEffect, useState} from 'react';
import {FlexBox, Solution} from "components";
import {Box, Typography} from "@mui/material";
import TabNavItem from "components/tabs/TabNavItem";

import TabContent from "components/tabs/TabContent";
import {comax_tech_sheet} from "assets/documents/imports";
import {useNavigate} from "react-router-dom";
import {tl_50_5t3, tl_55_5t1, tl_55_6t1, tls_55_13_1, tls_60_14_1, tls_60_14_SR1, tls_65_15_1, tls_65_B_10T_1,
    tls_65_SR_10T_1, tls_75_14T_1, tls_75_14T_2, tls_75B_18T_1, tls_80_32T_1, tls_80_32T_2, tls_80_32T_3, slh_70_4_1,
    slh_80_1, slh_110_1, slh_205R_1, sl_320_1, sl_450_1, sl_450R_1, sl_730_1, h_25_1, h_32_1, ht_47_1} from "./imports";

const TowerCrane = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("tab1");

    const Desktop = () => {

        return (
            <FlexBox id="solutions" sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                padding: "2rem 5.5rem 3rem 6rem", backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top Cranes" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-Jib Cranes" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Erecting Cranes" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 2.3rem 0rem 2.5rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top Cranes" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-Jib Cranes" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Erecting Cranes" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 2.3rem 0rem 2.5rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.6rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top Cranes" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-Jib Cranes" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Erecting Cranes" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.4rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "16px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-J" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Er." id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.4rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "14px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-J" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Er." id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  alignItems: "stretch", m: "2rem 0rem 0rem 0rem",
                p: "2rem 1.2rem 0rem 1.2rem",  backgroundColor: "var(--color-gray-bg)", }}>

                <Typography p= "0rem 2rem 2.5rem 0rem"
                            fontWeight="bold" fontSize="1.3rem" fontFamily="var(--font-family)" >

                    Tower Crane Solutions
                </Typography>

                <Box component="ul"
                     sx={{ width: "100%", margin: "0 auto 2rem", display: "flex",
                         justifyContent: "space-between", border: "1px solid #39A2DB",
                         borderRadius: "2rem", paddingLeft: "0px", fontFamily: "var(--font-family)",
                         fontWeight: "400", fontSize: "14px", lineHeight: "20px", alignItems: "stretch",
                         'li:first-child': {
                             borderBottomLeftRadius: "2rem", borderTopLeftRadius: "2rem"
                         },
                         'li:last-child': {
                             borderBottomRightRadius: "2rem", borderTopRightRadius: "2rem"
                         }, }} >

                    <TabNavItem title="Flat-Top" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Luffing-J" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Self-Er." id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>

                </Box>

                <TabContent id="tab1" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={tl_50_5t3} title="FLAT-TOP TL 50 5T" clickEvent= {() => { navigate("/flat-top-tl-50-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_5t1} title="FLAT-TOP TL 55 5T" clickEvent= {() => { navigate("/flat-top-tl-55-5t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tl_55_6t1} title="FLAT-TOP TL 55 6T" clickEvent= {() => { navigate("/flat-top-tl-55-6t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_55_13_1} title="FLAT-TOP TLS 55-13" clickEvent= {() => { navigate("/flat-top-tls-55-13") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_1} title="FLAT-TOP TLS 60-14" clickEvent= {() => { navigate("/flat-top-tls-60-14") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_60_14_SR1} title="FLAT-TOP TLS 60 14 SR" clickEvent= {() => { navigate("/flat-top-tls-60-14-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_15_1} title="FLAT-TOP TLS 65-15" clickEvent= {() => { navigate("/flat-top-tls-65-15") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_B_10T_1} title="FLAT-TOP TLS 65 B 10T" clickEvent= {() => { navigate("/flat-top-tls-65b-10t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_65_SR_10T_1} title="FLAT-TOP TLS 65 10T SR" clickEvent= {() => { navigate("/flat-top-tls-65-10t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_1} title="FLAT-TOP TLS 75 14T" clickEvent= {() => { navigate("/flat-top-tls-75-14t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75_14T_2} title="FLAT-TOP TLS 75 14T SR" clickEvent= {() => { navigate("/flat-top-tls-75-14t-sr") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_75B_18T_1} title="FLAT-TOP TLS 75B 18T" clickEvent= {() => { navigate("/flat-top-tls-75b-18t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_1} title="FLAT-TOP TLS 80 20T" clickEvent= {() => { navigate("/flat-top-tls-80-20t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_2} title="FLAT-TOP TLS 80 24T" clickEvent= {() => { navigate("/flat-top-tls-80-24t") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={tls_80_32T_3} title="FLAT-TOP TLS 80 32T" clickEvent= {() => { navigate("/flat-top-tls-80-32t") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={slh_70_4_1} title="Luffing-Jib SLH 70.4" clickEvent= {() => { navigate("/luffing-jib-slh-70-4") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_80_1} title="Luffing-Jib SLH 80" clickEvent= {() => { navigate("/luffing-jib-slh-80") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_110_1} title="Luffing-Jib SLH 110" clickEvent= {() => { navigate("/luffing-jib-slh-110") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={slh_205R_1} title="Luffing-Jib SLH 205R" clickEvent= {() => { navigate("/luffing-jib-slh-205r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_320_1} title="Luffing-Jib SL 320" clickEvent= {() => { navigate("/luffing-jib-sl-320") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450_1} title="Luffing-Jib SL 450" clickEvent= {() => { navigate("/luffing-jib-sl-450") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_450R_1} title="Luffing-Jib SL 450 R" clickEvent= {() => { navigate("/luffing-jib-sl-450r") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={sl_730_1} title="Luffing-Jib SL 730" clickEvent= {() => { navigate("/luffing-jib-sl-730") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>
                    <FlexBox sx={{ flexDirection: "row", display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                        gridGap: "2.5rem", alignItems: "flex-start" }}>

                        <Solution img={h_25_1} title="Self-Erecting H25" clickEvent= {() => { navigate("/self-erecting-h25") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={h_32_1} title="Self-Erecting H32" clickEvent= {() => { navigate("/self-erecting-h32") }}
                                  tech_sheet={comax_tech_sheet}/>
                        <Solution img={ht_47_1} title="Self-Erecting HT47" clickEvent= {() => { navigate("/self-erecting-ht47") }}
                                  tech_sheet={comax_tech_sheet}/>

                    </FlexBox>
                </TabContent>

            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default TowerCrane;