import React, {useEffect} from 'react';
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {tls_65_B_10T_1, tls_65_B_10T_2, tls_65_B_10T_3} from "scenes/tower-crane/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";
import TowerCrane from "../tower-crane";

const FlatTopTLS65B10T = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are 
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            FLAT-TOP TLS TLS 65B 10T

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            This version of the TLS 65 has been structurally reinforced to achieve a higher load capacity. At the maximum radius of 65m, in 2 falls, it’s able to hoist up to 2,200Kg. In 4 falls, the maximum overall hoist is 10,000Kg, with 1,850Kg at maximum radius.
                            This crane, combined with reinforced tower sections, is engineered to support greater tensions and resistance to torsion, yet still achieves a freestanding height of 50m on S17 mast.
                            There is also the option to jack the crane, and the cage and hydraulics are
                            supplied with an innovative climbing-collar system to achieve great heights.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                TECHNICAL CHARACTERISTICS
                            </Typography>

                            <Box component="ul">
                                <Features>Model: TLS 65B 10T</Features>
                                <Features>Max. load (kg): 10,000</Features>
                                <Features>Jib (m.): 65</Features>
                                <Features>Max. capacity (kg): 2,200</Features>
                                <Features>Motor Hp/kW: 45 HP (33 Kw), 50 HP (35 Kw)</Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={tls_65_B_10T_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default FlatTopTLS65B10T;