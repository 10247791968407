import React, {useEffect} from 'react';
import {Header, About, Solutions, Footer} from "containers";

const Home = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                    isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                    isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>

            <About isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                    isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                    isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>

            <Solutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>


        </>
    );
};

export default Home;