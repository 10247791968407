import {CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme/theme";
import Layout from "scenes/layout";
import Home from "scenes/home";

import Projects from "scenes/projects";
import Blog from "scenes/blog";
import Contact from "scenes/contact";
import FormworkScaffolding from "scenes/formwork-scaffolding";
import Comax from "../comax";
import Adatto from "../adatto";
import BeamFlex from "../beam-flex";
import Optimo from "../optimo";
import CircularPillar from "../circular-pillar";
import Halliform from "../halliform";
import MfEarthRetaining from "../mf-earth-retaining";
import ORound from "../o-round";
import OmniEarthRetaining from "../omni-earth-retaining";
import Src240 from "../src-240";
import SRC240 from "../src-240";
import TowerCranes from "../tower-cranes";
import Props from "../props";
import BeamsPanels from "../beams-panels";
import AludeckSlab from "../aludeck-slab";
import AluplusSlab from "../aluplus-slab";
import TwentyFlex from "../20-flex";
import EcoFormwork from "../eco-formwork";
import MultiportalFormwork from "../multiportal-formwork";
import RoofSystem from "../roof-system";
import MultidirectionalScaffolding from "../multidirectional-scaffolding";
import FabricatedFrame from "../fabricated-frame";
import RiskFreeSafe from "../risk-free-safe-scaffold";
import PinsScaffolding from "../pins-scaffolding";
import TubeCoupler from "../tube-coupler";
import SirioDeck from "../sirio-deck";
import Spider from "../spider";
import VegaDeck from "../vega-deck";
import Gd600System from "../gd600-system";
import MASelfSinking from "../ma-self-sinking";
import Tc80ShoringTowers from "../tc80-shoring-towers";
import TC80ShoringTowers from "../tc80-shoring-towers";
import TC60Towers from "../tc60-towers";
import MulticomShoringTowers from "../multicom-shoring-towers";
import MulticomHighCapacityTowers from "../multicom-high-capacity-towers";
import Stages from "../stages";
import TribuneScaffolding from "../tribune-scaffolding";
import StaircasesWalkways from "../staircases-walkways";
import AudioLightingDirectorTower from "../audio-lighting-director-tower";
import FlatTopTL505T from "../flat-top-tl-50-5t";
import FlatTopTL555T from "../flat-top-tl-55-5t";
import FlatTopTL556T from "../flat-top-tl-55-6t";
import FlatTopTLS5513 from "../flat-top-tls-55-13";
import FlatTopTLS6014 from "../flat-top-tls-60-14";
import FlatTopTLS6014SR from "../flat-top-tls-60-14-sr";
import FlatTopTLS6510TSR from "../flat-top-tls-65-10t-sr";
import FlatTopTLS6515 from "../flat-top-tls-65-15";
import FlatTopTLS65B10T from "../flat-top-tls-65-b-10t";
import FlatTopTLS7514T from "../flat-top-tls-75-14t";
import FlatTopTLS7514TSR from "../flat-top-tls-75-14t-sr";
import FlatTopTLS75B18T from "../flat-top-tls-75b-18t";
import FlatTopTLS8020T from "../flat-top-tls-80-20t";
import FlatTopTLS8024T from "../flat-top-tls-80-24t";
import FlatTopTls8032t from "../flat-top-tls-80-32t";
import FlatTopTLS8032T from "../flat-top-tls-80-32t";
import LuffingJibSL320 from "../luffing-jib-sl-320";
import LuffingJibSL450 from "../luffing-jib-sl-450";
import LuffingJibSL450R from "../luffing-jib-sl-450r";
import LuffingJibSL730 from "../luffing-jib-sl-730";
import LuffingJibSLH704 from "../luffing-jib-slh-70.4";
import LuffingJibSLH80 from "../luffing-jib-slh-80";
import LuffingJibSLH110 from "../luffing-jib-slh-110";
import LuffingJibSLH205R from "../luffing-jib-slh-205r";
import SelfErectingH25 from "../self-erecting-h25";
import SelfErectingH32 from "../self-erecting-h32";
import SelfErectingHT47 from "../self-erecting-ht-47";
import TowerCrane from "../tower-crane";
import MarinePlywood from "../marine-plywood";
import AdminAllBlogs from "../adminAllBlogs";
import AdminBlog from "../adminBlog";
import EditBlog from "../editBlog";
import ViewBlog from "../view-blog";

const App = () => {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const isLargeDesktop = useMediaQuery('(min-width: 1278px)');
  const isNormalDesktop = useMediaQuery('(min-width: 1025px) and (max-width: 1279px)');
  const isSmallDesktop = useMediaQuery('(min-width: 916px) and (max-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 481px) and (max-width: 915px)');
  const isMobile = useMediaQuery('(min-width: 390px) and (max-width: 480px)');
  const isSmallMobile = useMediaQuery('(min-width: 320px) and (max-width: 389px)');
  const isFoldDevice = useMediaQuery('(min-width: 260px) and (max-width: 319px)');

  return (
      <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route element={<Layout isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                      isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                      isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />}>

                <Route path="/" element={<Navigate to="/home" replace />} />

                <Route path="/home" element={<Home isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tower-cranes" element={<TowerCranes isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/props" element={<Props isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/beams-panels" element={<BeamsPanels isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                     isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                     isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/projects" element={<Projects isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/blog" element={<Blog isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/contact" element={<Contact isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/formwork-scaffolding" element={<FormworkScaffolding isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                         isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                         isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/comax-formwork" element={<Comax isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/adatto-alu-formwork" element={<Adatto isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                              isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                              isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/beam-flex-formwork" element={<BeamFlex isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                    isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                    isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/optimo-formwork" element={<Optimo isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                     isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                     isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/circular-pillar-formwork" element={<CircularPillar isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                     isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                     isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/halliform-formwork" element={<Halliform isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                 isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                 isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/mf-earth-retaining" element={<MfEarthRetaining isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                 isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                 isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/o-round-formwork" element={<ORound isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                 isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                 isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/omni-earth-retaining" element={<OmniEarthRetaining isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                 isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                 isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/src-240" element={<SRC240 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                 isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                 isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/aludeck-slab" element={<AludeckSlab isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                        isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                        isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/20-flex-formwork" element={<TwentyFlex isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/aluplus-slab" element={<AluplusSlab isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/eco-formwork" element={<EcoFormwork isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/multiportal-formwork" element={<MultiportalFormwork isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/roof-system" element={<RoofSystem isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/multidirectional-scaffolding" element={<MultidirectionalScaffolding isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/fabricated-frame" element={<FabricatedFrame isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/risk-free-safe-scaffold" element={<RiskFreeSafe isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/pins-scaffolding" element={<PinsScaffolding isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tube-coupler" element={<TubeCoupler isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/sirio-deck" element={<SirioDeck isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/spider" element={<Spider isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/vega-deck" element={<VegaDeck isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/gd600-system" element={<Gd600System isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/ma-self-sinking" element={<MASelfSinking isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tc80-shoring-towers" element={<TC80ShoringTowers isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                       isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                       isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tc60-towers" element={<TC60Towers isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/multicom-shoring-towers" element={<MulticomShoringTowers isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/multicom-high-capacity-towers" element={<MulticomHighCapacityTowers isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                       isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                       isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/stages" element={<Stages isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tribune-scaffolding" element={<TribuneScaffolding isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/staircases-walkways" element={<StaircasesWalkways isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/audio-lighting-director-tower" element={<AudioLightingDirectorTower isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/tower-crane" element={<TowerCrane isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                         isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                         isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tl-50-5t" element={<FlatTopTL505T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tl-55-5t" element={<FlatTopTL555T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                         isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                         isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tl-55-6t" element={<FlatTopTL556T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                         isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                         isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-55-13" element={<FlatTopTLS5513 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                         isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                         isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-60-14" element={<FlatTopTLS6014 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-60-14-sr" element={<FlatTopTLS6014SR isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-65-10t-sr" element={<FlatTopTLS6510TSR isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-65-15" element={<FlatTopTLS6515 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-65b-10t" element={<FlatTopTLS65B10T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-75-14t" element={<FlatTopTLS7514T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-75-14t-sr" element={<FlatTopTLS7514TSR isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                             isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                             isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-75b-18t" element={<FlatTopTLS75B18T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-80-20t" element={<FlatTopTLS8020T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-80-24t" element={<FlatTopTLS8024T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                             isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                             isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/flat-top-tls-80-32t" element={<FlatTopTLS8032T isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                             isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                             isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-sl-320" element={<LuffingJibSL320 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                             isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                             isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-sl-450" element={<LuffingJibSL450 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-sl-450r" element={<LuffingJibSL450R isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-sl-730" element={<LuffingJibSL730 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-slh-70-4" element={<LuffingJibSLH704 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                            isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                            isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-slh-80" element={<LuffingJibSLH80 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-slh-110" element={<LuffingJibSLH110 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/luffing-jib-slh-205r" element={<LuffingJibSLH205R isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/self-erecting-h25" element={<SelfErectingH25 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/self-erecting-h32" element={<SelfErectingH32 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/self-erecting-ht47" element={<SelfErectingHT47 isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                           isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                           isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/marine-plywood" element={<MarinePlywood isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/conformsc-efxf2587u-admin-all-blogposts" element={<AdminAllBlogs isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                      isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                      isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/conformsc-yuh879i09-admin-blog" element={<AdminBlog isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                               isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                               isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/conformsc-hu75ok0ba-edit-blog" element={<EditBlog isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                  isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                  isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

                <Route path="/conformsc-543fg67u9-view-blog" element={<ViewBlog isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                                                                isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                                                                isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice}/>} />

              </Route>
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
  );
}

export default App;
