import React, {useEffect} from 'react';
import { FormworkSolutions } from "containers";
import {FlexBox} from "components";
import {Box, Button, Typography} from "@mui/material";

import Carousel from "react-bootstrap/Carousel";
import {multicom_1, multicom_2, multicom_3} from "containers/formwork-solutions/imports";
import {comax_tech_sheet} from "assets/documents/imports";
import Features from "components/utilComponents/Features";

const MulticomShoringTowers = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile, isFoldDevice }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 3rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 0.5rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row", }}>
                            <Carousel
                                style={{flex: "auto", marginLeft: "0.5rem", width: "600px", }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "0rem 2.25rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>
                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 2rem 0rem 1.5rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "row",}}>
                            <Carousel style={{flex: "auto", marginLeft: "1rem", width: "750px" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 0.5rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 0.5rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "200px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "200px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", }}>

                        <FlexBox sx={{ flexDirection: "column",}}>
                            <Carousel style={{flex: "auto", width: "90%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 1.3rem 0rem 0rem",}}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "165px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "165px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 1rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 0rem 0rem 1rem", mr: "2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "150px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Technical sheet
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "150px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.5rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    The catalog
                                </Button>
                            </a>

                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem", alignItems: "flex-start",}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Multicom Shoring Towers

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            The universal shoring system based on CONDOR multidirectional 
                                     scaffolding elements which offers great design versatility saving execution time.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                FEATURES
                            </Typography>

                            <Box component="ul">
                                <Features>Simplicity, lightness, flexibility and cost effectiveness</Features>

                                <Features>Based on standard and modular elements which are more advantageous in many situations compared with traditional shoring</Features>
                                <Features>Easy design, in case of standard situations with height up to 30m</Features>
                                <Features>Easy to use, intuitive assembly and disassembly allowing the realization of variable section structures</Features>
                                <Features>Vertical and horizontal 50 cm modularity (allowing maximum flexibility in design and application);
                                </Features>
                                <Features>Variable load capacity from 30 to 50 kN per standard (therefore, a tower with four standards has a 200 kN maximum capacity);
                                </Features>
                                <Features>Easy to handle with castor wheels full integrated with the tower in case of transportation that allow transportation without disassembly</Features>
                                <Features>Perfect integration with staircases tower and full compatibility with the system for easy and fast access to all heights of the work</Features>
                                <Features>Suitable for casting significantly thick slabs, such as underpasses, bridges, viaducts and tunnels</Features>
                                <Features>Wide range of accessories and components</Features>
                            </Box>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                THE SYSTEM
                            </Typography>

                            <Box component="ul">
                                <Features>The system is made up of standards, ledgers and diagonals, hot dip galvanized, and offers the chance of being used as single tower or multiple linked towers</Features>

                                <Features>Based on the multidirectional scaffolding, it preserves all its advantages, thus offering great weight/capacity ratio, elements modularity, fast and intuitive assembly and a significant versatility in designing complex plano-altimetric shapes.
                                </Features>
                            </Box>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "column",  p: "3rem 0rem 0rem 0rem", alignItems: "flex-start",}}>

                        <FlexBox sx={{ flexDirection: "column", p: "0rem 0rem 0rem 1rem", alignItems: "flex-start",}}>
                            <Carousel style={{flex: "auto", width: "93%" }}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={multicom_2}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "column", p: "3rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                            <Typography
                                fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                                Download Centre

                            </Typography>

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Download technical data sheets, catalogs and other relevant documents and documentation
                            </Typography>

                        </FlexBox>

                        <FlexBox sx={{ flexDirection: "row", p: "2rem 0rem 0rem 1rem", }}>
                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="outlined" disableElevation
                                    sx={{
                                        background: "transparent", border: "2px solid #88C0FF", width: "110px",
                                        color: "var(--color-text)", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: 'transparent',
                                        },
                                    }}>
                                    Tech. D
                                </Button>
                            </a>

                            <a href={comax_tech_sheet} target='_blank'>
                                <Button
                                    variant="contained" disableElevation
                                    sx={{
                                        ml: "2rem", background: "#88C0FF", border: "2px solid #88C0FF", width: "110px",
                                        color: "#ffffff", textTransform: "none", fontSize: "1rem",
                                        fontWeight: "500", fontFamily: "var(--font-family)", p: "0.5rem 0.2rem",
                                        borderRadius: "7px",
                                        ':hover': {
                                            border: "2px solid #88C0FF", backgroundColor: '#88C0FF',
                                        },
                                    }}>
                                    Cat.
                                </Button>
                            </a>
                        </FlexBox>

                    </FlexBox>

                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default MulticomShoringTowers;