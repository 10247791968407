import React, {useEffect} from 'react';
import {Button, Typography} from "@mui/material";
import {FlexBox} from "components";
import { FormworkSolutions } from "containers";

import {useNavigate} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { formwork1, formwork2, formwork3, formwork4 } from "containers/formwork-solutions/imports";

const FormworkScaffolding = ({ isLargeDesktop, isNormalDesktop, isSmallDesktop, isTablet, isMobile, isSmallMobile,
                                 isFoldDevice }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Desktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 1rem 1rem 2rem", padding: "0 4rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 5rem 0rem 0rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "row",  p: "0rem 0.5rem 0rem 5rem",}}>
                        <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallDesktop = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "row", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 2.25rem 0rem 1.5rem",}}>
                        <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Tablet = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 2.5rem 0rem 2.5rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="2rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "1", flexDirection: "row",  p: "3rem 2rem 0rem 1.5rem",}}>
                        <Carousel style={{flex: "auto", marginLeft: "1rem", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const Mobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.3rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 0rem 0rem 0rem",}}>
                        <Carousel style={{flex: "auto", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const SmallMobile = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.4rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 0rem 0rem 0rem",}}>
                        <Carousel style={{flex: "auto", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    const FoldDevice = () => {
        return (
            <FlexBox sx={{ flexDirection: "column",  p: "0rem", alignItems: "stretch", }}>

                <FlexBox sx={{ flexDirection: "column", m: "2rem 0rem 1rem 0rem", paddingBottom: "2rem"}}>

                    <FlexBox sx={{ flex: "1", flexDirection: "column", p: "0rem 1.4rem 0rem 1.2rem", alignItems: "flex-start",}}>
                        <Typography
                            fontWeight="bold" fontSize="1.4rem" fontFamily="var(--font-family)" >

                            Formwork and Scaffolding

                        </Typography>

                        <Typography
                            marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                            textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                            Advanced formwork systems for the construction of structures for walls in general. Choose the
                            technological innovation of CFSL modular reinforced concrete formwork, beam formwork systems,
                            metal formwork, circular formwork.

                            <Typography
                                marginTop="2rem" fontSize="1rem" fontFamily="var(--font-family)" lineHeight= "30px"
                                textAlign= "justify" textJustify = "inter-word" color= "var(--color-text)">

                                Our solutions are top class in the industry. We harness best practises with a motivated and highly
                                competent workforce, delivering with excellence and exceeding expectations
                            </Typography>

                        </Typography>

                    </FlexBox>

                    <FlexBox sx={{ flex: "0.9", flexDirection: "column",  p: "3rem 0rem 0rem 0rem",}}>
                        <Carousel style={{flex: "auto", width: "90%" }}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork2}
                                    alt="Second slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork3}
                                    alt="Third slide"
                                />

                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={formwork4}
                                    alt="Third slide"
                                />

                            </Carousel.Item>
                        </Carousel>
                    </FlexBox>
                </FlexBox>

                <FormworkSolutions isLargeDesktop = {isLargeDesktop} isNormalDesktop = {isNormalDesktop}
                                   isSmallDesktop = {isSmallDesktop} isTablet = {isTablet} isMobile = {isMobile}
                                   isSmallMobile = {isSmallMobile} isFoldDevice = {isFoldDevice} />
            </FlexBox>
        )
    }

    return (
        <>
            {isLargeDesktop && (
                <Desktop />
            )}

            {isNormalDesktop && (
                <Desktop />
            )}

            {isSmallDesktop && (
                <SmallDesktop />
            )}

            {isTablet && (
                <Tablet />
            )}

            {isMobile && (
                <Mobile />
            )}

            {isSmallMobile && (
                <SmallMobile />
            )}

            {isFoldDevice && (
                <FoldDevice />
            )}
        </>
    );
};

export default FormworkScaffolding;